.reaction-selector {
  margin-top: 10px;
}

.reaction-selector__list {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.reaction-selector__button {
  background: #3498db;
  border-radius: 50px;
  border: none;
  color: #bdc3c7;
  width: 50px;
  height: 50px;
  font-size: 30px;
  text-shadow: 0 0 3px rgba(0, 0, 0, 0.5);
}

.reaction-selector__button:hover {
  color: white;
}

.reaction-selector__button--active {
  background: #e67e22;
  color: white;
  text-shadow: 0 0 3px rgba(255, 255, 255, 0.5);
}
