@tailwind base;
@tailwind components;
@tailwind utilities;

@import "./typography.scss";

html {
  font-size: 20px;
  height: -webkit-fill-available;
}

body {
  padding: 0;
  margin: 0;
  position: relative;
  background-size: cover;
  display: grid;
  grid-template-rows: auto 1fr;
  min-height: 100vh;
}

.page {
  padding: 20px 0;
}

@media (min-width: 600px) {
  .page {
    padding: 20px 20px;
  }
}

@media (min-width: 800px) {
  .page {
    padding: 20px 40px;
  }
}

.split-layout {
  display: flex;
  flex-direction: column;
  margin: 0 -20px;
  padding: 10px;
  text-align: center;
  justify-content: center;
}

.split-layout--small {
  padding: 10px;
}

.split-layout--large {
  padding: 10px;
}

@media (min-width: 600px) {
  .split-layout {
    flex-direction: row;
  }

  .split-layout--small {
    width: 33%;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  .split-layout--large {
    width: 66%;
  }
}

@layer base {
  h1 {
    @apply text-2xl;
    @apply font-black;
  }

  h2 {
    @apply text-2xl;
    @apply font-extrabold;
  }

  h3 {
    @apply text-xl;
    @apply font-extrabold;
  }

  a {
    @apply hover:underline;
    @apply font-medium;
  }

}

a {
  @apply text-orange-600;
}

.react-colorful {
  width: auto !important;
}