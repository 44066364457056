.animation-list {
  display: flex;
  flex-wrap: wrap;
  margin: -10px;
}

.animation-list__item {
  position: relative;
  flex: 1 1 250px;
  margin: 10px;
  max-width: 450px;
}

.animation-list__item--selected {
  box-shadow: 0 0 10px rgba(0, 0, 255, 0.5);
}

.animation-list__item-inner {
  background: white;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.5);
  overflow: hidden;
  max-width: 450px;
  width: 100%;
  height: 100%;
  position: relative;
}

.animation-list__placeholder {
  color: #888;
  display: flex;
  align-items: center;
  justify-content: center;
}

.animation-list__item:nth-child(3n + 1) {
  transform: rotate(-2deg);
}

.animation-list__item:nth-child(3n + 2) {
  transform: rotate(1.5deg);
}

.animation-list__item:nth-child(3n + 3) {
  transform: rotate(0.3deg);
}

.animation-list__item:nth-child(3n + 4) {
  transform: rotate(-1deg);
}

.animation-list__item:nth-child(3n + 5) {
  transform: rotate(1deg);
}

.animation-list__item::before,
.animation-list__placeholder::before {
  content: '';
  height: 0;
  width: 1px;
  margin-left: -1px;
  float: left;
  padding-top: 75%;
}

.animation-list__item::after {
  content: '';
  display: table;
  clear: both;
}

.animation-item__title-bar {
  box-sizing: border-box;
  position: absolute;
  top: 0;
  background: rgba(255, 255, 255, 0.9);
  width: 100%;
  overflow-wrap: break-word;
  animation: slide-down 0.5s ease-out both;
  transform: translateY(-100%);
}

.animation-list__item--selected .animation-item__title-bar {
  background: rgba(128, 128, 255, 0.5);

  a {
    color: black;
  }
}

.animation-item__title-bar a:hover {
  text-decoration: underline;
}

.animation-item__title-bar:hover {
  opacity: 1;
}

.animation-list__item-more-text {
  color: #333;
  // font-size: 20px;
  white-space: nowrap;
}

.animation-item__reactions {
  position: absolute;
  bottom: 5px;
  left: 5px;
  color: white;
  font-size: 18px;
}

.animation-item__image {
  width: 100%;
  height: 100%;
  object-fit: cover;
  opacity: 0.8;
  animation: fade-in 0.5s ease-in-out both;
  position: absolute;
}

.animation-list__item .animation-list__item-inner a:hover .animation-item__image {
  opacity: 1 !important;
}

.animation-item__stack {
  z-index: -1;
  width: 100%;
  height: 100%;
  background: #eee;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.5);
  position: absolute;
  top: 5px;
  left: 5px;
}

.animation-item__stack:nth-child(1) {
  z-index: -1;
  top: 5px;
  left: 5px;
  box-shadow: 0 0 4px rgba(0, 0, 0, 0.4);
  transform: rotate(1deg);
}

.animation-item__stack:nth-child(2) {
  z-index: -2;
  top: 10px;
  left: 10px;
  background: #ddd;
  box-shadow: 0 0 3px rgba(0, 0, 0, 0.3);
  transform: rotate(2deg);
}

.animation-item__stack:nth-child(3) {
  z-index: -3;
  top: 15px;
  left: 15px;
  background: #ccc;
  box-shadow: 0 0 2px rgba(0, 0, 0, 0.2);
  transform: rotate(3deg);
}

.animation-item__stack:nth-child(4) {
  z-index: -4;
  top: 20px;
  left: 20px;
}

.animation-item__stack:nth-child(5) {
  z-index: -5;
  top: 25px;
  left: 25px;
}

@keyframes slide-down {
  from {
    transform: translateY(-100%)
  }

  to {
    transform: translateY(0%)
  }
}

@keyframes fade-in {
  from {
    opacity: 0;
    transform: scale(1.1, 1.1)
  }

  to {
    opacity: 0.8;
    transform: scale(1, 1)
  }
}