.thread-title {
  position: relative;
  background: #fff;
  border-radius: 20px;
  padding: 10px 20px;
  color: #000;
  display: inline-block;
}

.thread-title:before {
  content: "";
  width: 0px;
  height: 0px;
  position: absolute;
  border-left: 24px solid #fff;
  border-right: 12px solid transparent;
  border-top: 12px solid #fff;
  border-bottom: 20px solid transparent;
  left: 20px;
  bottom: -24px;
}

.thread-competition-entries .thread-animation-container {
  margin: 0.5em;
}

.thread-animation-info {
  font-size: 16px;
  color: #444;
  background: #eee;
  padding: 5px;
}

.thread-animation {
  display: block;
  width: 100%;
}

.thread-post:target {
  background-color: #ffa;
  border: 3px red;
}

.thread-post--hidden {
  opacity: 0.5;
}

.thread-post__user-info {
  padding: 5px 0;
  border-bottom: 2px solid #ddd;
}

.thread-post__post-date {
  font-size: 20px;
  color: #666;
  padding: 10px;
}

.thread-post__inner {
  border-radius: 10px;
  margin-bottom: 10px;
  display: inline-block;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.4);
}

.thread-post:nth-child(odd) .thread-post__inner {
  background-color: rgba(255, 255, 255, 0.5);
}

.thread-post:nth-child(even) .thread-post__inner {
  background-color: rgba(255, 255, 255, 0.8);
}

.lock-icon,
.pinned-icon,
.competition-icon {
  margin-left: 10px;
  color: #666;
}

.thread-post-user-inline {
  background: #b90;
  color: white;
  border-radius: 5px;
  text-decoration: none;
  padding: 2px 5px;
}

.thread-post-user-inline:hover {
  background: #fff;
  color: #b90;
}