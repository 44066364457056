.error {
  background: #c0392b;
  padding: 10px;
  border-radius: 10px;
  color: white;
}

.error p {
  margin: 0;
}
