.header {
  background: rgba(255, 255, 255, 0.9);
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  padding: 5px 20px;
  line-height: 40px;
  z-index: 10;
}

.main-nav {
  flex: 1 0 auto;
  display: flex;

  a {
    margin-right: 0.5em;
  }
}