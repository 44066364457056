.reaction {
  background: #f39c12;
  border-radius: 10px;
  padding: 2px 5px;
  margin-right: 5px;
  box-shadow: 0 0 3px rgba(0, 0, 0, 0.5);
  font-size: 14px;
  color: white;
  display: inline-block;
}

.reaction--large {
  font-size: 30px;
}

.reaction--comments {
  background: #03a9f4;
}

.reactions-shower {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 80vh;
  pointer-events: none;
  z-index: 10;
  overflow: hidden;
}

.reactions-shower__item {
  position: absolute;
  top: 0;
  animation: ReactionShower 5s 1s ease-in forwards;
  transform: translateY(-100px);
  font-size: 50px;
}

@keyframes ReactionShower {
  from {transform: translateY(-100px); opacity: 0.8} 
  to {transform: translateY(80vh);  opacity: 0}
}