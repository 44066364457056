@font-face {
    font-family: 'Comic Neue';
    src: local('Comic Neue Light'), local('ComicNeue-Light'),
        url('../../public/fonts/ComicNeue/ComicNeue-Light.woff2') format('woff2'),
        url('../../public/fonts/ComicNeue/ComicNeue-Light.woff') format('woff');
    font-weight: 300;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Comic Neue';
    src: local('Comic Neue Italic'), local('ComicNeue-Italic'),
        url('../../public/fonts/ComicNeue/ComicNeue-Italic.woff2') format('woff2'),
        url('../../public/fonts/ComicNeue/ComicNeue-Italic.woff') format('woff');
    font-weight: normal;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Comic Neue';
    src: local('Comic Neue Bold'), local('ComicNeue-Bold'),
        url('../../public/fonts/ComicNeue/ComicNeue-Bold.woff2') format('woff2'),
        url('../../public/fonts/ComicNeue/ComicNeue-Bold.woff') format('woff');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Comic Neue';
    src: local('Comic Neue Bold Italic'), local('ComicNeue-BoldItalic'),
        url('../../public/fonts/ComicNeue/ComicNeue-BoldItalic.woff2') format('woff2'),
        url('../../public/fonts/ComicNeue/ComicNeue-BoldItalic.woff') format('woff');
    font-weight: bold;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Comic Neue';
    src: local('Comic Neue Light Italic'), local('ComicNeue-LightItalic'),
        url('../../public/fonts/ComicNeue/ComicNeue-LightItalic.woff2') format('woff2'),
        url('../../public/fonts/ComicNeue/ComicNeue-LightItalic.woff') format('woff');
    font-weight: 300;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Comic Neue';
    src: local('Comic Neue Regular'), local('ComicNeue-Regular'),
        url('../../public/fonts/ComicNeue/ComicNeue-Regular.woff2') format('woff2'),
        url('../../public/fonts/ComicNeue/ComicNeue-Regular.woff') format('woff');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Comic Neue Angular';
    src: local('Comic Neue Angular Bold'), local('ComicNeueAngular-Bold'),
        url('../../public/fonts/ComicNeue/ComicNeueAngular-Bold.woff2') format('woff2'),
        url('../../public/fonts/ComicNeue/ComicNeueAngular-Bold.woff') format('woff');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Comic Neue Angular';
    src: local('Comic Neue Angular Italic'), local('ComicNeueAngular-Italic'),
        url('../../public/fonts/ComicNeue/ComicNeueAngular-Italic.woff2') format('woff2'),
        url('../../public/fonts/ComicNeue/ComicNeueAngular-Italic.woff') format('woff');
    font-weight: normal;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Comic Neue Angular';
    src: local('Comic Neue Angular Bold Italic'), local('ComicNeueAngular-BoldItalic'),
        url('../../public/fonts/ComicNeue/ComicNeueAngular-BoldItalic.woff2') format('woff2'),
        url('../../public/fonts/ComicNeue/ComicNeueAngular-BoldItalic.woff') format('woff');
    font-weight: bold;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Comic Neue Angular';
    src: local('Comic Neue Angular Light Italic'), local('ComicNeueAngular-LightItalic'),
        url('../../public/fonts/ComicNeue/ComicNeueAngular-LightItalic.woff2') format('woff2'),
        url('../../public/fonts/ComicNeue/ComicNeueAngular-LightItalic.woff') format('woff');
    font-weight: 300;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Comic Neue Angular';
    src: local('Comic Neue Angular Regular'), local('ComicNeueAngular-Regular'),
        url('../../public/fonts/ComicNeue/ComicNeueAngular-Regular.woff2') format('woff2'),
        url('../../public/fonts/ComicNeue/ComicNeueAngular-Regular.woff') format('woff');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Comic Neue Angular';
    src: local('Comic Neue Angular Light'), local('ComicNeueAngular-Light'),
        url('../../public/fonts/ComicNeue/ComicNeueAngular-Light.woff2') format('woff2'),
        url('../../public/fonts/ComicNeue/ComicNeueAngular-Light.woff') format('woff');
    font-weight: 300;
    font-style: normal;
    font-display: swap;
}